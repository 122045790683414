import { mapGetters, mapState } from 'vuex';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

import createBookmarkMutation from '@/apollo/mutations/bookmark/createBookmark';
import deleteBookmarkMutation from '@/apollo/mutations/bookmark/deleteBookmark';

export default {
  mixins: [GtmEventsMixin],
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    ...mapState({
      /* eslint-disable */
      isMobile: (state) => state.isMobile
      /* eslint-enable */
    }),
    debugParams() {
      // Stroeer demo campaign url params
      if (!this.$config.DEBUG_LOGS) return '';
      return '';
      // return this.isMobile ? '?sdmad=demo=uap' : '?sdgkv=demo=uap';
    }
  },
  methods: {
    clickDeal(deal) {
      this.$store.commit('setSelectedDeal', deal);
    },
    clickShare(deal) {
      this.$store.commit('setShowShareModal', true);
      this.$store.commit('setSelectedDeal', deal);
    },
    clickBookmark(deal) {
      var location;
      if (this.$route.path === '/') {
        location = 'homepage';
      } else if (['/deals/', '/angebote/', '/aanbiedingen/'].includes(this.$route.path)) {
        location = 'deal_overview_page';
      } else {
        location = 'dealpage';
      }

      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'deal_favored',
        location
      });

      if (!this.isAuthenticated) {
        this.$store.commit('setShowAuthModal', true);
      } else if (deal.bookmark) {
        this.deleteBookmark(deal);
      } else {
        this.createBookmark(deal);
      }
    },
    async createBookmark(deal) {
      /* eslint-disable */
      try {
        await this.$apollo.mutate({
          mutation: createBookmarkMutation,
          variables: {
            itemUuid: deal.id,
            type: this.getBookmarkTypeEnumName(deal.__typename)
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createBookmark: {
              __typename: 'Bookmark',
              id: `new-bookmark-id`,
              type: this.getBookmarkTypeEnumName(deal.__typename),
              item: {
                __typename: 'BookmarkItem',
                ...deal,
                bookmark: {
                  __typename: 'Bookmark',
                  id: `new-bookmark-id`
                }
              }
            }
          }
        });

        this.$toast.success(this.$i18n.t('addBookmark'));
      } catch (error) {
        console.warn('Error', error);
        this.$toast.error(this.$i18n.t('errorHandling.bookmarkNotAdded'));
      }
    },
    async deleteBookmark(deal) {
      try {
        await this.$apollo.mutate({
          mutation: deleteBookmarkMutation,
          variables: {
            id: deal.bookmark.id
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteBookmark: {
              __typename: 'Bookmark',
              id: deal.bookmark.id,
              item: {
                __typename: this.getBookmarkTypeEnumName(deal.__typename),
                ...this.deal,
                bookmark: null
              }
            }
          }
        });
        this.$toast.info(this.$i18n.t('removeBookmark'));
      } catch (e) {
        console.warn('Error', e);
      }
    },
    getBookmarkTypeEnumName(type) {
      switch (type) {
        case 'AccommodationDeal':
          return 'ACCOMMODATION_DEAL';
        case 'DirectDeal':
          return 'DIRECT_DEAL';
        case 'TravelBasicDeal':
          return 'TRAVEL_BASIC_DEAL';
        case 'FlightDeal':
          return 'FLIGHT_DEAL';
        case 'TravelCombinationDeal':
          return 'TRAVEL_COMBINATION_DEAL';
        case 'PackageTourDeal':
          return 'PACKAGE_TOUR_DEAL';
        case 'AmadeusPackageTourDeal':
          return 'AMADEUS_PACKAGE_TOUR_DEAL';
        case 'BasicDeal':
        default:
          return 'BASIC_DEAL';
      }
    }
  }
};
