<template>
  <section>
    <div class="section-container content-container">
      <h2
        class="headline-cards"
        v-html="$t('landingpage.titleTrustpilot')"
      ></h2>
      <div class="trustpilot__div">
        <a
          :href="getUrl()"
          target="_blank"
          class="trustpilot__pseudo"
          @click="pushData()"
        ></a>
        <HgTrustpilot class="homepage__trustpilot" />
      </div>
    </div>
  </section>
</template>

<script>
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  mixins: [GtmEventsMixin],

  data() {
    return {
      url: 'https://www.trustpilot.com/review/holidayguru.de'
    };
  },

  methods: {
    getUrl() {
      switch (this.$i18n.locale) {
        default:
          return 'https://www.trustpilot.com/review/urlaubsguru.de';

        case 'de':
          return 'https://de.trustpilot.com/review/www.urlaubsguru.de';

        case 'at':
          return 'https://de.trustpilot.com/review/urlaubsguru.at';

        case 'ch':
          return 'https://de.trustpilot.com/review/holidayguru.ch';

        case 'nl':
          return 'https://nl.trustpilot.com/review/holidayguru.nl';

        case 'es':
          return 'https://es.trustpilot.com/review/holidayguru.es';
      }
    },

    pushData() {
      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'homepage_click_trustpilot'
      });
    }
  }
};
</script>

<style lang="scss">
.headline-cards > span {
  color: $teal-500;
  font-weight: 700;
}
</style>

<style lang="scss" scoped>
.section-container {
  padding: 0 rem(24);
}

.trustpilot__div {
  position: relative;
  max-width: rem(1046);
  margin-left: auto;
  margin-right: auto;
}

.trustpilot__pseudo {
  display: inline-block;
  width: rem(192);
  height: rem(160);
  position: absolute;
  z-index: 50;

  @media screen and (max-width: 590px) {
    left: 0;
    width: 100%;
  }
}

.homepage__trustpilot {
  margin-top: rem(40);
  margin-left: auto;
  margin-right: auto;
}

.headline-cards {
  font-size: rem(24);
  font-weight: 400;
  line-height: rem(27);
  margin: rem(56) 0 rem(24);

  @media screen and (min-width: 768px) {
    font-size: rem(36);
    line-height: rem(41);
    margin: rem(32) 0;
  }
}
</style>
