<template>
  <adSkeleton
    id-name="adup_contentAd_bottom"
    inline-style="width:100%;height:360px;"
    :placement-keys="placementKeys"
    :query="query"
    :locale="locale"
  />
</template>

<script>
import adSkeleton from '../../adSkeleton.vue'

export default {
  components: {
    adSkeleton
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    placementKeys: {
      default: () => {
        return {
          es: [
            'a6be06b353ab5ca5b4eab76a00b473dd', // Mobile
          ],
        }
      }
    }
  }
};
</script>
