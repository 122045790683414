<template>
  <section v-if="promotion" class="content-container">
    <a class="banner" :href="promotion.buttonLink" rel="noopener">
      <LazyImage
        class="image"
        :alt="promotion.image.title"
        :src="`${promotion.image.url}?width=920`"
        :src-placeholder="`${promotion.image.url}?width=20&height=10`"
      />
    </a>
  </section>
</template>

<script>
export default {
  components: {
    LazyImage: () => import('@/components/LazyImage'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.content-container {
  padding: 2rem 1rem 1rem;
  min-height: 4rem;
  max-height: 320px;
}

.banner {
  background-color: #fffcdf;
  color: $gray-900;
  position: relative;
  max-width: 920px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  aspect-ratio: 3.9 / 1;
}
</style>
