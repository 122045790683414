<template>
  <div id="infonline-container" />
</template>

<script>
export default {
  props: {
    cp: {
      type: String,
      default: 'content',
    },
    sv: {
      type: String,
      default: 'i2',
    },
  },
  mounted() {
    if (window.IOMm) {
      window.IOMm('configure', {
        st: 'urlaubsguru.de',
        dn: 'data-fd53e9bda6.urlaubsguru.de',
      });
    }

    this.loadScript()
  },
  methods: {
    async loadScript() {
      const postscribe = await import('postscribe');
      /* eslint-disable no-useless-escape */
      postscribe.default(
        '#infonline-container',
        `<!-- SZM VERSION="2.0" -->
        <script type="text/javascript">
        var iam_data = {
        "st":"urlguru",
        "cp":"${this.cp}",
        "sv":"${this.sv}",
        "co":"kommentar"
        }
        iom.c(iam_data);
        <\/script>
        <!--/SZM -->`,
      );
    },
  },
};
</script>
