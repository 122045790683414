var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-container"},[_c('div',{staticClass:"head-wrap"},[_c('h2',{class:`head-wrap__headline`,domProps:{"innerHTML":_vm._s(_vm.$t('landingpage.titleDeals'))}}),_vm._v(" "),_c('p',{class:`head-wrap__subline`},[_vm._v("\n      "+_vm._s(_vm.$t('landingpage.sublineDeals'))+"\n    ")]),_vm._v(" "),_c('Arrow',{staticClass:"arrow-icon"}),_vm._v(" "),_c('p',{staticClass:"deals-info"},[_c('strong',[_vm._v(_vm._s(_vm.currentPage * _vm.displayLimit))]),_vm._v(" "+_vm._s(_vm.$t('of'))+"\n      "),_c('strong',[_vm._v(_vm._s(_vm.totalDeals))]),_vm._v("\n      "+_vm._s(_vm.totalDeals > 1 ? _vm.$t('dealDative') : _vm.$t('dealSingular'))+"\n    ")])],1),_vm._v(" "),_c('hg-tabs',{staticClass:"tab-padding tab-container",attrs:{"id":"deals-tabs","theme":"light","activeTab":_vm.currentTabLP},on:{"onTabClicked":_vm.changeTab}},[_vm._l((_vm.tabCategories),function(category,index){return _c('template',{slot:category.name},[_c('div',{staticClass:"card-wrapper"},[(_vm.isLoading)?[_c('HgDealCardSkeletonRedesign'),_vm._v(" "),_c('HgDealCardSkeletonRedesign'),_vm._v(" "),_c('HgDealCardSkeletonRedesign'),_vm._v(" "),_c('HgDealCardSkeletonRedesign'),_vm._v(" "),_c('HgDealCardSkeletonRedesign'),_vm._v(" "),_c('HgDealCardSkeletonRedesign')]:(!_vm.isLoading && _vm.deals.length == 0)?[_c('NoResults',{attrs:{"showText":false,"textAlternative":_vm.$t('hotel-of-the-day.noResult')}})]:[(
              _vm.hotd &&
                (_vm.currentPageLP[_vm.currentTabLP] == 1 ||
                  !_vm.currentPageLP[_vm.currentTabLP]) &&
                (category.fetchTypes == 'ACCOMMODATION' ||
                  category.fetchTypes == null)
            )?_c('div',{staticClass:"card-inner-container"},[_c('hg-link-handler',{key:"hotd",attrs:{"link":_vm.localePath('hotd')},nativeOn:{"click":function($event){return (() => _vm.pushGtmData(_vm.hotd, true)).apply(null, arguments)}}},[(_vm.hotd && _vm.hotd.images)?_c('hg-card-hotel-of-the-day',{staticClass:"card-wrap",attrs:{"images":_vm.hotd && _vm.hotd.images,"is-mobile":_vm.isMobile,"headline":_vm.hotd && _vm.hotd.name,"ratingText":"Das Hotel hat noch keine Bewertungen","description":_vm.hotd.hotelOfTheDay && _vm.hotd.hotelOfTheDay.subtitle,"organizer":_vm.hotd &&
                    _vm.hotd.hotelOfTheDay &&
                    _vm.hotd.hotelOfTheDay.organizerName,"button":"Zeig mir das Hotel","rating":_vm.hotd && _vm.hotd.rating,"show-circle":"","deal-type":{
                  icon: '/assets/deal-type-icons/hotd-hotel.svg',
                  name: _vm.$t('hotel')
                },"orgaLogo":_vm.hotd &&
                    _vm.hotd.hotelOfTheDay.organizerImage &&
                    _vm.hotd.hotelOfTheDay.organizerImage.url,"link":_vm.localePath('hotd')}}):_c('HgDealCardSkeletonRedesign')],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.deals),function(deal){return (deal)?_c('div',{key:deal.id},[_c('hg-link-handler',{attrs:{"link":_vm.getDealLink(deal)},nativeOn:{"click":function($event){return (() => _vm.pushGtmData(deal, false)).apply(null, arguments)}}},[_c('hg-deal-card-redesign',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
                  (isVisible, entry) => _vm.viewChanged(isVisible, entry, deal)
                ),expression:"\n                  (isVisible, entry) => viewChanged(isVisible, entry, deal)\n                "}],staticClass:"card-wrap",attrs:{"id":deal.id,"title":deal.title,"subtitle":deal.subtitle,"deal-type":deal.getCategory(),"price":deal.price,"badge":deal.badge,"expiry-date":deal.expiryDate,"top-deal":deal.isTopDeal(),"badge-label":_vm.$t('topDeal'),"button-label":_vm.$t('goToDeal'),"show-buttons":true,"image":deal.image,"images":deal.images,"is-mobile":_vm.isMobile,"link":_vm.getDealLink(deal)},on:{"onClickBookmark":function($event){return _vm.clickBookmark(deal)},"onClickShare":function($event){return _vm.clickShare(deal)},"onClick":(data) => _vm.dealClick(data)}})],1)],1):_vm._e()})]],2)])})],2),_vm._v(" "),_c('div',{staticClass:"pagination-wrap"},[_c('vs-pagination',{attrs:{"total-pages":_vm.totalPages,"current-page":_vm.currentPage},on:{"change":(e) => _vm.changePage(e)},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('div',{class:[
            'pagination-arrows',
            {
              'pagination-arrows-loading': _vm.isLoading && _vm.paginationLoadingPrev
            }
          ],on:{"click":function($event){return _vm.paginationLoading('prev')}}},[_c('PaginationArrow',{staticClass:"pagination-prev"})],1)]},proxy:true},{key:"rightIcon",fn:function(){return [_c('div',{class:[
            'pagination-arrows',
            {
              'pagination-arrows-loading': _vm.isLoading && _vm.paginationLoadingNext
            }
          ],on:{"click":function($event){return _vm.paginationLoading('next')}}},[_c('PaginationArrow',{staticClass:"pagination-next"})],1)]},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"pagination-input-wrap"},[_c('p',{staticClass:"go-to-page"},[_vm._v(_vm._s(_vm.$t('goToPage')))]),_vm._v(" "),_c('input',{staticClass:"pagination-input",attrs:{"type":"text"},domProps:{"value":_vm.currentPage},on:{"change":(e) => _vm.changePage(e)}}),_vm._v(" "),_c('p',{staticClass:"mobile-go-to-page"},[_vm._v("\n        "+_vm._s(_vm.$t('of'))+" "),_c('span',[_vm._v(_vm._s(_vm.totalPages))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }