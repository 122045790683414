import {
  format,
  isAfter,
  isToday,
  isYesterday,
  isWithinInterval
} from 'date-fns';
import searchDealsQuery from '@/apollo/queries/deal/searchDeals';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import { mapState } from 'vuex';
import searchTagsQuery from '@/apollo/queries/deal/searchTags';

class Deal {
  constructor(
    deal,
    { createdTodayString, createdYesterdayString, createdDateString },
    dealCategories
  ) {
    this.id = deal.id || null;
    this.title = deal.title || null;
    this.subtitle = deal.subtitle || null;
    this.type = deal.__typename || null;
    this.__typename = deal.__typename || null;
    this.price = deal.price;
    this.badge = deal.badge || null;
    this.dates = {
      expireDate: deal.expiryDate || null,
      publishDate: deal.publishDate || null,
      createDate: deal.createDate || null
    };
    this.tags = deal.tags || [];
    this.url = deal.url || null;
    this.image = deal.featuredImage || null;
    this.images = [];
    this.pinnedDeal = deal.pinnedDeal || null;
    this.createdTodayString = createdTodayString || null;
    this.createdYesterdayString = createdYesterdayString || null;
    this.createdDateString = createdDateString || null;
    this.dealCategories = dealCategories || [];
    this.slug = deal.slug || null;

    if (deal && deal.featuredImages) {
      // Convert featuredImages or accommodation hotel images from { image: { url, ... } }[] to { url, ... }[]
      let images = deal.featuredImages;

      if (deal.accommodations && deal.accommodations.length > 0) {
        for (let image of deal.accommodations[0].hotel.images) {
          images.push(image);
        }
      }

      for (let image of images) {
        let i = this.images.findIndex((im) => im.url === image.image.url);
        if (i !== -1) continue;

        this.images.push({
          url: image.image.url,
          title: image.image.title
        });
      }
    }
  }

  isTopDeal() {
    return (
      this.tags.length > 0 &&
      this.tags.filter(
        (tag) => tag.tag.name && tag.tag.name.toLowerCase() === 'top deals'
      ).length > 0
    );
  }

  getFormattedDate(formatString, dateType) {
    const formatter = formatString || 'dd.MM.yyyy';

    switch (dateType) {
      case 'publishDate':
        return this.dates.publishDate
          ? format(new Date(this.dates.publishDate), formatter)
          : null;

      case 'expireDate':
        return this.dates.expireDate
          ? format(new Date(this.dates.expireDate), formatter)
          : null;

      case 'createDate':
        return this.dates.createDate
          ? format(new Date(this.dates.createDate), formatter)
          : null;

      default:
        return this.dates.publishDate
          ? format(new Date(this.dates.publishDate), formatter)
          : null;
    }
  }

  isExpired() {
    return isAfter(new Date(), new Date(this.dates.expireDate));
  }

  getCategory() {
    const category = this.dealCategories.find((category) =>
      category.typeID.includes(this.__typename)
    );

    return category ? category : this.dealCategories[0];
  }

  getTimeStamp(dateType) {
    const date =
      dateType && dateType === 'createDate'
        ? new Date(this.dates.createDate)
        : new Date(this.dates.publishDate);

    if (isToday(date)) {
      return this.createdTodayString;
    } else if (isYesterday(date)) {
      return this.createdYesterdayString;
    } else {
      return (
        this.createdDateString + ' ' + this.getFormattedDate(null, dateType)
      );
    }
  }

  isPinned() {
    return this.pinnedDeal && this.pinnedDeal.id;
  }

  getDealPathName() {
    switch (this.__typename) {
      case 'AccommodationDeal':
        return 'deal-accommodation-slug';
      case 'TravelBasicDeal':
        return 'deal-travel-basic-slug';
      case 'FlightDeal':
        return 'deal-flight-slug';
      case 'TravelCombinationDeal':
        return 'deal-travel-combination-slug';
      case 'PackageTourDeal':
        return 'deal-package-tour-slug';
      case 'AmadeusPackageTourDeal':
        return 'deal-amadeus-package-tour-slug';
      case 'BasicDeal':
      default:
        return 'deal-basic-slug';
    }
  }
}

export default {
  mixins: [GtmEventsMixin],

  computed: {
    ...mapState({
      currentPageLP: (state) => state.dealSearch.currentPageLP,
      searchTermState: (state) => state.dealSearch.searchTerm,
      tagIdsState: (state) => state.dealSearch.tagIds,
      idsState: (state) => state.dealSearch.ids
    })
  },

  data: () => ({
    totalDeals: 0,
    totalPages: 0,
    showMoreEnabled: true,
    isLoading: true,
    currentPage: 1,
    displayLimit: 12,
    deals: [],
    hotd: null,
    dealCategories: [],
    tags: [],
    searchDeals: [],
    paginationLoadingPrev: false,
    paginationLoadingNext: false,
    hotdOffset: 0,
    pinnedDeals: [],
    inViewPortCmps: []
  }),

  created() {
    if (this.$route.path == '/') {
      this.currentPage = this.currentPageLP[this.currentTabLP];
    }

    this.dealCategories = [
      {
        name: '',
        fetchTypes: null,
        typeID: []
      },
      {
        name: this.$i18n.t('dealcard.accommondation'),
        icon: '/assets/deal-type-icons/hotel.svg',
        fetchTypes: 'ACCOMMODATION',
        typeID: ['AccommodationDeal']
      },
      {
        name: this.$i18n.t('dealcard.amadeusPackageTour'),
        icon: '/assets/deal-type-icons/package_tour.svg',
        fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
        typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
      },
      {
        name: this.$i18n.t('dealcard.flight'),
        icon: '/assets/deal-type-icons/flight.svg',
        fetchTypes: 'FLIGHT',
        typeID: ['FlightDeal']
      },
      {
        name: this.$i18n.t('dealcard.travelBasic'),
        icon: '/assets/deal-type-icons/trip.svg',
        fetchTypes: ['TRAVEL_COMBINATION', 'DIRECT_DEAL', 'TRAVEL_BASIC'],
        typeID: [
          'TravelCombinationDeal',
          'DirectDeal',
          'TravelBasicDeal',
          'BasicDeal'
        ]
      }
    ];
    this.getTags();
  },

  watch: {
    searchTermState() {
      this.currentPage = 1;
      this.fetchMore();
    },
    tagIdsState() {
      this.currentPage = 1;
      this.fetchMore();
    },
    idsState() {
      this.currentPage = 1;
      this.fetchMore();
    },
    deals() {
      // Scroll to top of page when new deals are loaded, if it is not on first page, Document exists & user is not on top of page
      if (
        this.currentPage > 1 &&
        document &&
        document.documentElement.scrollTop > 150
      ) {
        document.getElementById('deals-tabs').scrollIntoView(true);
      }
    },
    searchDeals() {
      if (this.searchDeals && this.searchDeals.length > 0) {
        const tempArr = this.searchDeals.map((deal) => {
          return new Deal(
            deal,
            {
              createdTodayString: this.$i18n.t('time.createdTodayString'),
              createdYesterdayString: this.$i18n.t(
                'time.createdYesterdayString'
              ),
              createdDateString: this.$i18n.t('time.createdDateString')
            },
            this.dealCategories
          );
        });

        this.deals = tempArr.filter((el) => !this.deals.includes(el));

        tempArr.forEach((el) => {
          if (el.isPinned()) this.pinnedDeals.push(el);
        });

        this.isLoading = false;
        this.paginationLoadingPrev = false;
        this.paginationLoadingNext = false;
      } else {
        this.deals = [];
        this.isLoading = false;
        this.paginationLoadingPrev = false;
        this.paginationLoadingNext = false;
      }
    }
  },

  methods: {
    async getTags() {
      if (!this.idsState && this.$route.query.tagIds) {
        try {
          const {
            data: { searchTags }
          } = await this.$apollo.query({
            query: searchTagsQuery,
            variables: {
              filter: {
                ids: this.$route.query.tagIds ? this.$route.query.tagIds : ''
              }
            }
          });

          if (searchTags && searchTags.length > 0) {
            this.$store.commit('dealSearch/triggerSearch', {
              searchTerm: searchTags[0].name,
              tagIds: searchTags[0].id
            });
          }
        } catch (e) {
          // eslint-disable-next-line
          console.warn('error', e);
        }
      }
    },

    getOffset() {
      var offset;

      if (this.$route.path == '/') {
        offset =
          ((this.currentPageLP[this.currentTabLP] || 1) - 1) *
            this.displayLimit -
          this.hotdOffset;
      } else {
        offset = (this.currentPage - 1) * this.displayLimit;
      }

      return offset < 0 ? 0 : offset;
    },

    async fetchMore() {
      const searchTerm = this.searchTermState;
      const tagIds = this.tagIdsState;
      const ids = this.idsState;

      try {
        this.isLoading = true;
        const offset = this.getOffset();
        const {
          data: { searchDeals, _searchDealsMeta, searchHotels }
        } = await this.$apollo.query({
          query: searchDealsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            sort: this.orderBy ? this.orderBy : 'DATE_DESC',
            limit: this.displayLimit ? this.displayLimit : 500,
            includeHotelOfTheDay: this.includeHotd
              ? this.currentPageLP[this.currentTabLP] == 1
              : false,
            mimetype: 'webp',
            offset,
            filter: {
              platform: 'WEBSITE',
              pinnedDeals: this.fetchPinnedDeals,
              searchTerm: tagIds != null ? null : searchTerm,
              types: this.tabTerm ? this.tabTerm : null,
              tagIds,
              ids
            }
          }
        });
        this.searchDeals = searchDeals;
        this.totalDeals = _searchDealsMeta ? _searchDealsMeta.count : 0;
        this.totalPages = Math.ceil(this.totalDeals / this.displayLimit);

        let hotd;

        if (searchHotels && searchHotels.length) hotd = searchHotels[0];

        if (
          this.hotd &&
          (this.currentPageLP[this.currentTabLP] == 1 ||
            !this.currentPageLP[this.currentTabLP]) &&
          (this.tabTerm === null || this.tabTerm === 'ACCOMMODATION')
        ) {
          this.hotdOffset = 1;

          if (this.searchDeals.length >= 12) this.searchDeals.pop();
        }

        // Check if the hotel of the day is valid for the current day
        if (
          hotd &&
          hotd.hotelOfTheDay &&
          isWithinInterval(new Date(), {
            start: new Date(hotd.hotelOfTheDay.visibilityStartDate),
            end: new Date(hotd.hotelOfTheDay.visibilityEndDate)
          })
        ) {
          if (this.searchDeals.length >= 12) this.searchDeals.pop();
          this.hotdOffset = 1;

          const images = [];

          for (let image of hotd.images) {
            if (image && image.image) {
              images.push(image.image);
            }
          }

          this.hotd = {
            ...hotd,
            images
          };
        }
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e);
      }

      this.isLoading = false;
      this.paginationLoadingPrev = false;
      this.paginationLoadingNext = false;
    },

    viewChanged(isVisible, entry, deal) {
      if (isVisible && !this.inViewPortCmps.includes(deal)) {
        this.inViewPortCmps.push(deal);
        const location = this.$route.path;

        if (location === '/') {
          this.$gtm.push({
            event: 'gtmEvent',
            location: 'homepage',
            eventName: 'homepage_impression_deal',
            impression: {
              name: deal.title,
              position: this.inViewPortCmps.length
            }
          });
        } else if (
          ['/deals/', '/angebote/', '/aanbiedingen/'].includes(location)
        ) {
          this.$gtm.push({
            event: 'gtmEvent',
            location: 'deal_overview_page',
            eventName: 'homepage_impression_deal',
            impression: {
              name: deal.title,
              position: this.inViewPortCmps.length
            }
          });
        }
      }
    }
  }
};
