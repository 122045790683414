<template>
  <div class="section-container content-container">
    <h2 class="headline-usp-categories" v-html="$t('landingpage.titleUSP')" />

    <div
      class="usp-wrapper"
      :class="{ animation: uspAnimation }"
      v-observe-visibility="{ callback: enterMethod, once: true }"
    >
      <hg-icon-with-headline
        v-for="(usp, index) in showUSP"
        :key="index"
        class="usp-item"
        :headline="usp.headline"
        :subHeadline="usp.subHeadline"
        :img="usp.image"
      />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showUSP: [],
    uspAnimation: false,
  }),

  methods: {
    enterMethod(val) {
      this.uspAnimation = val;
    },
  },

  created() {
    this.showUSP = [
      {
        headline: this.$t('USP.headline.deals'),
        subHeadline: this.$t('USP.subHeadline.deals'),
        image: '/assets/images/usps/angebote.svg',
      },
      {
        headline: this.$t('USP.headline.advisory'),
        subHeadline: this.$t('USP.subHeadline.advisory'),
        image: '/assets/images/usps/beratung.svg',
      },
      {
        headline: this.$t('USP.headline.booking'),
        subHeadline: this.$t('USP.subHeadline.booking'),
        image: '/assets/images/usps/booking.svg',
      },
      {
        headline: this.$t('USP.headline.inspiration'),
        subHeadline: this.$t('USP.subHeadline.inspiration'),
        image: '/assets/images/usps/inspiration.svg',
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.section-container {
  padding: 0 rem(24);
}

.usp-item {
  opacity: 0;
  transition: all 1.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(50px);

  .animation & {
    opacity: 1;
    transform: translateY(0);

    &:nth-child(1) {
      transition-delay: 0.2s;
    }

    &:nth-child(2) {
      transition-delay: 0.5s;
    }

    &:nth-child(3) {
      transition-delay: 0.9s;
    }

    &:nth-child(4) {
      transition-delay: 1.3s;
    }
  }
}

.headline-usp-categories {
  font-size: rem(24);
  font-weight: 400;
  line-height: rem(27);
  margin: rem(56) 0 rem(24);

  @media screen and (min-width: 768px) {
    font-size: rem(36);
    line-height: rem(41);
    margin: rem(32) 0;
  }
}

.usp-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: rem(24) 0;

  @media screen and (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(24) rem(24);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>

<style lang="scss">
.headline-usp-categories > span {
  color: $teal-500;
  font-weight: 700;
}
</style>
