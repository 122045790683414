<template>
  <section class="content-container">
    <div class="head-wrap">
      <h2
        :class="`head-wrap__headline`"
        v-html="$t('landingpage.titleDeals')"
      />

      <p
        :class="`head-wrap__subline`"
      >
        {{ $t('landingpage.sublineDeals') }}
      </p>
      <Arrow class="arrow-icon" />
      <p class="deals-info">
        <strong>{{ currentPage * displayLimit }}</strong> {{ $t('of') }}
        <strong>{{ totalDeals }}</strong>
        {{ totalDeals > 1 ? $t('dealDative') : $t('dealSingular') }}
      </p>
    </div>
    <hg-tabs
      id="deals-tabs"
      theme="light"
      @onTabClicked="changeTab"
      :activeTab="currentTabLP"
      class="tab-padding tab-container"
    >
      <template
        v-for="(category, index) in tabCategories"
        :slot="category.name"
      >
        <div class="card-wrapper">
          <template v-if="isLoading">
            <HgDealCardSkeletonRedesign />
            <HgDealCardSkeletonRedesign />
            <HgDealCardSkeletonRedesign />
            <HgDealCardSkeletonRedesign />
            <HgDealCardSkeletonRedesign />
            <HgDealCardSkeletonRedesign />
          </template>
          <template v-else-if="!isLoading && deals.length == 0">
            <NoResults
              :showText="false"
              :textAlternative="$t('hotel-of-the-day.noResult')"
            />
          </template>
          <template v-else>
            <div
              v-if="
                hotd &&
                  (currentPageLP[currentTabLP] == 1 ||
                    !currentPageLP[currentTabLP]) &&
                  (category.fetchTypes == 'ACCOMMODATION' ||
                    category.fetchTypes == null)
              "
              class="card-inner-container"
            >
              <hg-link-handler
                :link="localePath('hotd')"
                key="hotd"
                @click.native="() => pushGtmData(hotd, true)"
              >
                <hg-card-hotel-of-the-day
                  class="card-wrap"
                  v-if="hotd && hotd.images"
                  :images="hotd && hotd.images"
                  :is-mobile="isMobile"
                  :headline="hotd && hotd.name"
                  ratingText="Das Hotel hat noch keine Bewertungen"
                  :description="
                    hotd.hotelOfTheDay && hotd.hotelOfTheDay.subtitle
                  "
                  :organizer="
                    hotd &&
                      hotd.hotelOfTheDay &&
                      hotd.hotelOfTheDay.organizerName
                  "
                  button="Zeig mir das Hotel"
                  :rating="hotd && hotd.rating"
                  show-circle
                  :deal-type="{
                    icon: '/assets/deal-type-icons/hotd-hotel.svg',
                    name: $t('hotel')
                  }"
                  :orgaLogo="
                    hotd &&
                      hotd.hotelOfTheDay.organizerImage &&
                      hotd.hotelOfTheDay.organizerImage.url
                  "
                  :link="localePath('hotd')"
                ></hg-card-hotel-of-the-day>
                <HgDealCardSkeletonRedesign v-else />
              </hg-link-handler>
            </div>

            <div v-for="deal in deals" v-if="deal" :key="deal.id">
              <hg-link-handler
                :link="getDealLink(deal)"
                @click.native="() => pushGtmData(deal, false)"
              >
                <hg-deal-card-redesign
                  class="card-wrap"
                  :id="deal.id"
                  :title="deal.title"
                  :subtitle="deal.subtitle"
                  :deal-type="deal.getCategory()"
                  :price="deal.price"
                  :badge="deal.badge"
                  :expiry-date="deal.expiryDate"
                  :top-deal="deal.isTopDeal()"
                  :badge-label="$t('topDeal')"
                  :button-label="$t('goToDeal')"
                  :show-buttons="true"
                  :image="deal.image"
                  :images="deal.images"
                  :is-mobile="isMobile"
                  :link="getDealLink(deal)"
                  @onClickBookmark="clickBookmark(deal)"
                  @onClickShare="clickShare(deal)"
                  @onClick="(data) => dealClick(data)"
                  v-observe-visibility="
                    (isVisible, entry) => viewChanged(isVisible, entry, deal)
                  "
                />
              </hg-link-handler>
            </div>
          </template>
        </div>
      </template>
    </hg-tabs>

    <!-- pagination section-->
    <div class="pagination-wrap">
      <vs-pagination
        :total-pages="totalPages"
        :current-page="currentPage"
        @change="(e) => changePage(e)"
      >
        <template #leftIcon>
          <div
            :class="[
              'pagination-arrows',
              {
                'pagination-arrows-loading': isLoading && paginationLoadingPrev
              }
            ]"
            @click="paginationLoading('prev')"
          >
            <PaginationArrow class="pagination-prev" />
          </div>
        </template>
        <template #rightIcon>
          <div
            :class="[
              'pagination-arrows',
              {
                'pagination-arrows-loading': isLoading && paginationLoadingNext
              }
            ]"
            @click="paginationLoading('next')"
          >
            <PaginationArrow class="pagination-next" />
          </div>
        </template>
      </vs-pagination>
      <div class="pagination-input-wrap">
        <p class="go-to-page">{{ $t('goToPage') }}</p>
        <input
          :value="currentPage"
          type="text"
          @change="(e) => changePage(e)"
          class="pagination-input"
        />
        <p class="mobile-go-to-page">
          {{ $t('of') }} <span>{{ totalPages }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import DealCardMixin from '@/mixins/DealCardMixin';
import SearchDealsMixin from '~/mixins/SearchDealsMixin';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

import Arrow from '@/components/ui/icons/arrow';
import PaginationArrow from '@/assets/svgs/pagination-arrow-svg.vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      currentPageLP: (state) => state.dealSearch.currentPageLP,
      currentTabLP: (state) => state.dealSearch.currentTabLP,
    })
  },
  components: {
    Arrow,
    PaginationArrow
  },
  transition: 'deal-cards-fade',
  mixins: [DealCardMixin, SearchDealsMixin, GtmEventsMixin],
  data: () => ({
    tabTerm: null,
    tabCategories: [],
    fetchPinnedDeals: true,
    includeHotd: true
  }),
  created() {
    this.internationalTabCategories = {
      dev: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: 'PackageTourDeal',
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: [, 'PACKAGE_TOUR'],
          typeID: [, 'PackageTourDeal']
        },
        {
          name: 'AmadeusPackageTourDeal',
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL'],
          typeID: ['AmadeusPackageTourDeal']
        },
        {
          name: 'TRAVEL_COMBINATION',
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_COMBINATION'],
          typeID: ['TravelCombinationDeal']
        },
        {
          name: 'DirectDeal',
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['DIRECT_DEAL'],
          typeID: ['DirectDeal']
        },
        {
          name: 'TravelBasicDeal',
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_BASIC'],
          typeID: ['TravelBasicDeal']
        },
        {
          name: 'BasicDeal',
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['BASIC'],
          typeID: ['BasicDeal']
        },
        {
          name: 'Flight',
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['FLIGHT'],
          typeID: ['Flight']
        }
      ],
      de: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: this.$i18n.t('tabCategories.amadeusPackageTour'),
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
          typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
        },
        {
          name: this.$i18n.t('tabCategories.travelBasic'),
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_COMBINATION', 'DIRECT_DEAL', 'TRAVEL_BASIC'],
          typeID: [
            'TravelCombinationDeal',
            'DirectDeal',
            'TravelBasicDeal',
            'BasicDeal'
          ]
        }
      ],
      at: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: this.$i18n.t('tabCategories.amadeusPackageTour'),
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
          typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
        },
        {
          name: this.$i18n.t('tabCategories.travelBasic'),
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_COMBINATION', 'DIRECT_DEAL', 'TRAVEL_BASIC'],
          typeID: [
            'TravelCombinationDeal',
            'DirectDeal',
            'TravelBasicDeal',
            'BasicDeal'
          ]
        }
      ],
      ch: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: this.$i18n.t('tabCategories.amadeusPackageTour'),
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
          typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
        },
        {
          name: this.$i18n.t('tabCategories.travelBasic'),
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_COMBINATION', 'DIRECT_DEAL', 'TRAVEL_BASIC'],
          typeID: [
            'TravelCombinationDeal',
            'DirectDeal',
            'TravelBasicDeal',
            'BasicDeal'
          ]
        }
      ],
      es: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: this.$i18n.t('tabCategories.amadeusPackageTour'),
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
          typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
        },
        {
          name: this.$i18n.t('tabCategories.travelBasic'),
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['TRAVEL_COMBINATION', 'DIRECT_DEAL', 'TRAVEL_BASIC'],
          typeID: [
            'TravelCombinationDeal',
            'DirectDeal',
            'TravelBasicDeal',
            'BasicDeal'
          ]
        }
      ],
      // NL without "TravelBasic" category, instead "DirectDeal"
      nl: [
        {
          name: this.$i18n.t('tabCategories.chooseAll'),
          fetchTypes: null,
          typeID: []
        },
        {
          name: this.$i18n.t('tabCategories.accommondation'),
          icon: '/assets/deal-type-icons/hotel.svg',
          fetchTypes: 'ACCOMMODATION',
          typeID: ['AccommodationDeal']
        },
        {
          name: this.$i18n.t('tabCategories.amadeusPackageTour'),
          icon: '/assets/deal-type-icons/package_tour.svg',
          fetchTypes: ['AMADEUS_PACKAGE_TOUR_DEAL', 'PACKAGE_TOUR'],
          typeID: ['AmadeusPackageTourDeal', 'PackageTourDeal']
        },
        {
          name: this.$i18n.t('tabCategories.travelBasic'),
          icon: '/assets/deal-type-icons/trip.svg',
          fetchTypes: ['DIRECT_DEAL'],
          typeID: ['DirectDeal']
        }
      ]
    };

    this.fetchMore();
    this.getTabsCategories();
  },
  methods: {
    pushGtmData(deal, isHotd = false) {
      if (isHotd)
        this.$gtm.push({
          event: 'gtmEvent',
          eventName: 'click_to_hotel',
          clickedElement: 'deal_card',
          hotelName: deal.name
        });
      else
        this.$gtm.push({
          event: 'gtmEvent',
          eventName: 'click_to_deal',
          clickedElement: 'deal_card',
          dealName: deal.title,
          isTopDeal: deal.isTopDeal(),
          uId: deal.id
        });
    },
    changePage(page) {
      var clickedElement = 'number/arrow';

      if (typeof page !== 'number') {
        clickedElement = 'input_field';
        page = parseFloat(page.target.value);
      }

      if (/\d/.test(page)) {
        page = Number(page);
        if (page > this.totalPages) {
          this.currentPage = this.totalPages;
        } else if (page < 1) {
          this.currentPage = 1;
        } else {
          this.currentPage = page;
        }

        this.$store.commit('dealSearch/setCurrentPageLP', {
          index: this.currentTabLP,
          page: this.currentPage
        });

        this.$gtm.push({
          event: 'gtmEvent',
          eventName: 'homepage_pagination_click',
          elementName: this.currentPage,
          clickedElement: clickedElement,
          location:
            window.location.pathname === '/' ? 'homepage' : 'deal_overview_page'
        });

        this.fetchMore();
      }
    },
    getTabsCategories() {
      if (this.$route.query.debug) {
        // eslint-disable-next-line
        console.log('DEBUG-MODE ENABLED');
        this.tabCategories = this.tabCategories = this.internationalTabCategories.dev; // For testing with all possible OfferTypes
      } else {
        this.tabCategories = this.internationalTabCategories[this.$i18n.locale];
      }
    },
    getDealLink(deal) {
      if (
        (deal.type === 'DirectDeal' ||
          deal.type === 'AmadeusPackageTourDeal') &&
        deal.price &&
        deal.price.currency
      ) {
        return deal.price.forwarderUrl;
      }

      return this.localePath({
        name: deal.getDealPathName(),
        params: { slug: deal.slug }
      });
    },
    changeTab(tab) {
      if (this.currentTabLP !== tab) {
        this.$gtm.push({
          event: 'gtmEvent',
          eventName: 'homepage_click_offer_category',
          elementName: this.tabCategories[tab].name // Pauschalreise, Flug, Reise
        });
        this.isLoading = true;
        this.tabTerm = this.tabCategories[tab].fetchTypes;
        this.$store.commit('dealSearch/setCurrentTabLP', tab);
        this.currentPage = this.currentPageLP[tab] || 1;
        this.fetchMore();
      }
    },
    moreResults() {
      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'homepage_click_show_more_offers'
      });
      this.fetchMore();
    },
    dealClick(data) {
      this.$gtm.push(data);
    },
    paginationLoading(direction) {
      if (direction === 'next') {
        this.paginationLoadingNext = true;
      } else if (direction === 'prev') {
        this.paginationLoadingPrev = true;
      }
    }
  }
};
</script>

<style lang="scss">
.head-wrap__headline > span {
  color: $teal-500;
  font-weight: 700;
}
</style>

<style lang="scss" scoped>
.testStyle {
  background-color: red !important;
}

.testStyle2 {
  background-color: green !important;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  align-items: stretch;
  margin-bottom: rem(16);

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 600px));
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(200px, 600px));
  }
}

.card-inner-container {
  max-width: 100%;
}

.card-wrap {
  height: 100%;
}

.fetch-more-button {
  text-align: center;

  &.disabled {
    filter: grayscale(100%);
    opacity: 0.4;
  }
}

.deal-cards-fade-enter-active,
.deal-cards-fade-leave-active {
  transition: opacity 0.9s, transform 0.7s;
  transform: translateY(0);
}

.deal-cards-fade-enter,
.deal-cards-fade-leave {
  opacity: 0;
  transform: translateY(-20px);
}

.head-wrap {
  position: relative;
  margin: rem(56) rem(24) rem(24);

  @media screen and (min-width: 768px) {
    margin: rem(96) rem(24) rem(32);
  }

  .head-wrap__headline {
    font-size: rem(24);
    font-weight: 400;
    line-height: rem(27);

    @media screen and (min-width: 768px) {
      font-size: rem(36);
      line-height: rem(41);
    }
  }

  .head-wrap__subline {
    margin-top: rem(15);
    max-width: rem(800);
  }

  .arrow-icon {
    display: none;
    visibility: hidden;
    transform: scaleX(-1);

    @media screen and (min-width: 1600px) {
      display: block;
      position: absolute;
      visibility: visible;
      z-index: 1;
      width: auto;
      height: rem(80);
      top: 140%;
      right: rem(-80);
    }
  }
}

.loading {
  text-align: center;

  svg {
    margin: 0 auto rem(15);
    width: rem(40);
    height: auto;
    animation: loading 1.5s infinite alternate;
  }
}

@keyframes loading {
  from {
    opacity: 0.5;
    filter: grayscale(100%);
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    filter: grayscale(0);
    transform: scale(1);
  }
}

.tab-container {
  margin: 0 rem(8);
}

.tab-padding {
  padding: 0 rem(16);
}

.deals-info {
  background-color: $teal-60;
  display: inline-block;
  padding: rem(12);
  border-radius: rem(8);
}

.pagination-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: rem(10);

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
    gap: rem(40);
  }
}

.pagination-input-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(8);
  flex-basis: 100%;
  height: rem(42);

  @media screen and (min-width: 768px) {
    flex-basis: auto;
    gap: rem(15);

    &::before {
      content: '';
      position: absolute;
      left: rem(-20);
      height: 100%;
      width: rem(1);
      background-color: $teal-200;
    }
  }

  p {
    margin: 0;
    font-size: rem(14);
  }

  .go-to-page {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .mobile-go-to-page {
    display: block;

    @media screen and (min-width: 768px) {
      display: none;
    }

    span {
      color: $teal-500;
    }
  }
}

.pagination-input {
  max-width: rem(48);
  width: rem(48);
  height: rem(32);
  max-height: rem(32);
  border: rem(1) solid $gray-200;
  border-radius: rem(8);
  padding: 0 rem(8);
  height: rem(42);
  font-size: rem(14);
  pointer-events: auto;

  &:focus {
    outline-color: $teal-400;
  }
}

.pagination-arrows {
  position: relative;
  width: rem(40);
  height: rem(40);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid $black;
    border-radius: 50%;
  }

  &:hover {
    .pagination-prev,
    .pagination-next {
      stroke: $black;
    }
  }

  .pagination-prev {
    transform: rotate(180deg);
  }

  &.pagination-arrows-loading {
    &::before {
      content: '';
      border-bottom-color: $teal-400;
      animation: rotation 0.8s infinite linear;
    }

    .pagination-prev,
    .pagination-next {
      stroke: $teal-400;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
// Pagination Styling
// Leave Unscoped!!!
.pagination-wrap {
  nav {
    pointer-events: auto;
  }

  .vs-pagination {
    pointer-events: auto;

    @media screen and (max-width: 768px) {
      width: rem(100);
      justify-content: space-between;
    }

    > li {
      > a {
        width: rem(30);
        height: rem(30);
        color: $black;
        margin: 0 rem(4);

        @media screen and (min-width: 768px) {
          width: rem(40);
          height: rem(40);
        }

        &:hover {
          background-color: transparent;
        }
      }

      &.vs-pagination--active a {
        background-color: $teal-500;
        border-radius: 50%;
      }

      &:not(:last-of-type):not(:first-of-type) {
        // no hover on pagination arrows
        > a {
          &:hover {
            background-color: $teal-40;
            border-radius: 50%;
          }
        }

        // hide pagination numbers on mobile
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .vs-pagination--disabled {
      a {
        opacity: 0.2;
      }
    }
  }
}

.handler-style {
  flex-direction: column;
  display: grid;
  transform: translateY(0);
  transition: box-shadow 0.25s, transform 0.25s;
  border-radius: 15px;
}
</style>
