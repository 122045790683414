<template>
  <div></div>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from 'vuex';
import CookieConsentMixin from '@/mixins/CookieConsentMixin';

export default {
  mixins: [CookieConsentMixin],
  middleware: ['isAppView'],
  head() {
    if (!window) return;
    if (this.stroeerLoaded) return;

    const locale = this.$i18n.locale;
    let adUrl =
      'https://cdn.stroeerdigitalgroup.de/metatag/live/urlaubsguru.de/metaTag.min.js';

    if (locale === 'de') {
      adUrl =
        'https://cdn.stroeerdigitalgroup.de/metatag/live/urlaubsguru.de/metaTag.min.js';
    } else if (locale === 'ch') {
      adUrl =
        'https://cdn.stroeerdigitalgroup.de/metatag/live/holidayguru.ch/metaTag.min.js';
    } else if (locale === 'at') {
      adUrl =
        'https://cdn.stroeerdigitalgroup.de/metatag/live/urlaubsguru.at/metaTag.min.js';
    } else if (locale === 'es') {
      adUrl =
        'https://cdn.stroeerdigitalgroup.de/metatag/live/holidayguru.es/metaTag.min.js';
    } else if (locale === 'nl') {
      adUrl =
        'https://cdn.stroeerdigitalgroup.de/metatag/live/holidayguru.nl/metaTag.min.js';
    }
    // see Navbar.vue
    // adUrl += '?sdmad=demo=uap'; // mobile Demo Parameter
    // adUrl += '?sdgkv=demo=uap'; // Demo Parameter

    return {
      script: [
        {
          hid: 'stroeer',
          src: adUrl,
          defer: true,
          callback: () => {
            this.$store.commit('ads/setStroeerLoaded', true);

            window.addEventListener('metaTagSystemCmpConsentAvailable', () => {
              this.$store.commit('ads/setStroeerConsentAvailable', true);
              this.$store.commit(
                'ads/setStroeerTriggerAdSlots',
                new Date().toISOString()
              );
              this.$store.dispatch('tracking/init', this.$route);
              this.consentCheck();
            });
            window.addEventListener('metaTagSystemCmpChoiceSelected', () => {
              this.$store.commit(
                'ads/setStroeerTriggerAdSlots',
                new Date().toISOString()
              );
              this.$store.dispatch('tracking/init', this.$route);
              this.consentCheck();
            });
          }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      stroeerLoaded: (state) => state.ads.stroeerLoaded,
      isAppView: (state) => state.isAppView
    })
  }
};
</script>
