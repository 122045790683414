<template>
  <HgNewsletterBanner @onSubmit="subscribeNewsletter" :variant="variant" />
</template>

<script>
import createNewsletterUserMutation from '@/apollo/mutations/newsletter/createNewsletterUser';

export default {
  props: {
    variant: {
      type: String,
      default: '1'
    }
  },
  methods: {
    async subscribeNewsletter(params) {
      try {
        const {
          data: { createNewsletterUser }
        } = await this.$apollo.mutate({
          mutation: createNewsletterUserMutation,
          variables: {
            firstName: params.firstName,
            lastName: ' ',
            email: params.email
          }
        });

        if (createNewsletterUser) {
          this.$toast.success(this.$i18n.t('newsletterBox.successTitle'));
        }
      } catch (e) {
        this.$toast.error(this.$i18n.t('errorHandling.404.title'));
      }
    }
  }
};
</script>
