<template>
  <main>
    <!-- Stroeer Superbanner -->
    <div
      v-if="['es'].includes($i18n.locale)"
      id="stroeerHomepageSuperbannerArea"
      ref="stroeerHomepageSuperbannerArea"
      class="banner ads-spacing"
    />

    <!-- Content Sections -->
    <Homepage
      :sections="page ? page.sections : []"
      :newsletterBannerVariationKey="this.newsletterBannerVariationKey"
    />

    <!-- Pubperform -->
    <div
      v-if="['es'].includes($i18n.locale)"
      id="stroeerHomepagePubperform"
      class="banner ads-spacing"
    />

    <!-- Link List (SEO) -->
    <LinkListSection class="section-spacing" />

    <!-- Stroeer -->
    <client-only>
      <StroeerLoader />
    </client-only>

    <!-- Infonline -->
    <template v-if="$i18n.locale === 'de' && allowInfonline">
      <Infonline cp="home" sv="ke" />
    </template>
  </main>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import { KameleoonClient } from '@kameleoon/nodejs-sdk';
import { KameleoonEventSource } from '@kameleoon/nodejs-event-source';
import { KameleoonRequester } from '@kameleoon/nodejs-requester';
import { KameleoonVisitorCodeManager } from '@kameleoon/nodejs-visitor-code-manager';
import AdSlotsMixin from '@/mixins/AdSlotsMixin';
import LazyHydrate from 'vue-lazy-hydration';
import Homepage from '@/components/content/Homepage.vue';
import LinkListSection from '@/components/sections/LinkListSection.vue';
import StroeerLoader from '@/components/partials/StroeerLoader.vue';
import Infonline from '@/components/tracking/Infonline.vue';

export default {
  components: {
    LazyHydrate,
    Homepage,
    LinkListSection,
    StroeerLoader,
    Infonline
  },
  mixins: [AdSlotsMixin],
  created() {
    if (process.env.NODE_ENV === 'development') {
      this.isDev = true;
    }
  },
  computed: {
    ...mapState({
      allowAdup: (state) => state.ads.allowAdup,
      allowInfonline: (state) => state.ads.allowInfonline,
      adupLoaded: (state) => state.ads.adupLoaded,
      stroeerLoaded: (state) => state.ads.stroeerLoaded,
      isMobile: (state) => state.isMobile
    })
  },
  data: () => ({
    isDev: false,
    dataLoadedForAds: true, // needed for adSlotsToLoad
    page: null,
    newsletterBannerVariationKey: null
  }),
  mounted() {
    // Add kameleoon engine code to document
    const script = document.createElement('script');
    script.textContent = this.engineCode;
    document.body.appendChild(script);
  },
  async asyncData({ req, res }) {
    const siteCode = process.env.KAMELEOON_SITE_CODE;
    const clientId = process.env.KAMELEOON_CLIENT_ID;
    const clientSecret = process.env.KAMELEOON_CLIENT_SECRET;

    if (siteCode == null || clientId == null || clientSecret == null) {
      return null;
    }

    const client = new KameleoonClient({
      siteCode: process.env.KAMELEOON_SITE_CODE,
      credentials: {
        clientId: clientId,
        clientSecret: clientSecret
      },
      externals: {
        eventSource: new KameleoonEventSource(),
        visitorCodeManager: new KameleoonVisitorCodeManager(),
        requester: new KameleoonRequester()
      }
    });

    try {
      await client.initialize();
    } catch (error) {
      console.error('Error in initializing KameleoonClient');
      console.error(error);
      return null;
    }

    let visitorCode = ""

    try {
      visitorCode = client.getVisitorCode({
        request: req,
        response: res,
      })
    } catch (error) {
      console.error('Error in getVisitorCode');
      console.error(error);
      return null;
    }

    let variationKey = ""

    try {
      variationKey = client.getFeatureFlagVariationKey(
        visitorCode,
        'newsletterbanner',
      )
    } catch (error) {
      console.error('Error in getFeatureFlagVariationKey');
      console.error(error);
      return null;
    }

    let engineCode = ""

    try {
      engineCode = client.getEngineTrackingCode(visitorCode)
    } catch (error) {
      console.error('Error in getEngineTrackingCode');
      console.error(error);
      return null;
    }

    return {
      newsletterBannerVariationKey: variationKey,
      engineCode
    }
  },
  apollo: {
    /*getStaticPage: {
      query: getStaticPage,
      prefetch: true,
      errorPolicy: 'all',
      variables: {
        area: 'FRONTPAGE',
        limit: 9,
        offset: 0,
        sort: 'DATE_DESC'
      },

      result({ data }) {
        try {
          this.page = data.getStaticPage.page;
        }catch (e){
          this.page = { sections :  []}
        }
      },
      error(error) {
        console.warn('Index Error', error);
        if(error.graphQLErrors && error.graphQLErrors.length > 0) {
          console.warn('Error', error.graphQLErrors);
          this.error =
            error.graphQLErrors[0].message &&
            error.graphQLErrors[0].message.error &&
            error.graphQLErrors[0].message.error.errorCode
              ? error.graphQLErrors[0].message.error.errorCode
              : error.graphQLErrors[0].message;
        } else {
          this.page = { sections :  []}
          this.error = "Unknown Error"
        }
      }
    }*/
  }
};
</script>

<style scoped lang="scss">
.hg-dropdown-wrapper .hg-dropdown-trigger {
  z-index: unset;
}

.section-spacing {
  margin-top: rem(40);

  @media (max-width: 768px) {
    margin-top: rem(80);
  }
}

.ad-section {
  max-width: rem(650);
  margin: 0 auto;
}

.ad-section--wide {
  max-width: rem(1440);
  padding-top: 0;
  padding-bottom: 0;
}
</style>
