<template>
  <section v-if="shownCards.length > 0">
    <div class="section-container content-container">
      <div class="head-wrap">
        <h2
          class="headline-cards-categories"
          v-html="$t('landingpage.titleCategory')"
        ></h2>
        <p>{{ $t('landingpage.subtitleCategory') }}</p>
        <Arrow class="arrow-icon" />
      </div>
      <div class="category-card-wrapper grid-container">
        <transition-group name="deal-cards-fade">
          <a
            v-for="(card, index) in shownCards"
            :key="`${index}_${card.startDate}`"
            :href="card.link"
            @click="(e) => e.preventDefault()"
          >
            <HgCardMoods
              class="category-card-content"
              :mood="card.name"
              :deals="card.deals"
              :image="card.image"
              :alt="card.alt || 'asdf'"
              theme="category"
              @onClick="() => pushDataLayer(card)"
              v-observe-visibility="
                (isVisible, entry) => viewChanged(isVisible, entry, card)
              "
            />
          </a>
        </transition-group>
      </div>

      <div class="button-wrap">
        <hg-button
          color="teal"
          @onClick="showMore()"
          :class="{ 'fetch-more-disabled': !showMoreEnabled }"
          :disabled="!showMoreEnabled"
        >
          <template v-if="showMoreEnabled">
            {{ $t('showMoreCategories') }}
          </template>
          <template v-else>
            {{ $t('categoryCard.noMoreCategories') }}
          </template>
        </hg-button>
      </div>
    </div>
  </section>
</template>

<script>
import Arrow from '~/components/ui/icons/arrow';
import { isPast, isFuture } from 'date-fns';

import cardCategories from './cardCategories';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import allHighlightCategories from '@/apollo/queries/categories/allHighlightCategories.gql';

export default {
  mixins: [GtmEventsMixin, cardCategories],
  components: { Arrow },
  props: {
    link: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'de'
    }
  },
  data() {
    return {
      showCategories: 4,
      showMoreEnabled: true,
      shownCards: [],
      localeCards: [],
      inViewPortCmps: []
    };
  },
  methods: {
    viewChanged(isVisible, entry, card) {
      if (isVisible && !this.inViewPortCmps.includes(card.name)) {
        this.inViewPortCmps.push(card.name);

        this.$gtm.push({
          event: 'gtmEvent',
          eventName: 'homepage_impression_holiday_category',
          impression: {
            name: card.name,
            position: this.inViewPortCmps.length
          }
        });
      }
    },
    checkTimeWindow(startDate, endDate) {
      if (startDate && endDate) {
        let result = isPast(startDate) && isFuture(endDate);
        return result;
      }
      return true;
    },
    showCard() {
      if (this.showCategories >= this.categoryCards.length) {
        this.showMoreEnabled = false;
      }
      this.shownCards = this.categoryCards.slice(0, this.showCategories);
    },
    showMore() {
      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'homepage_click_show_more_categories'
      });

      this.showCategories += 4;
      this.showCard();
    },
    pushDataLayer(card) {
      this.$gtm.push({
        event: "gtmEvent",
        eventName: "homepage_click_holiday_category",
        categoryName: card.name
      });

      if (card) window.location.href = card.link;
    }
  },

  apollo: {
    allHighlightCategories: {
      query: allHighlightCategories,
      variables: {
        filter: {
          platform: "WEBSITE"
        }
      }
    }
  }
};
</script>

<style lang="scss">
.headline-cards-categories > span {
  color: $teal-500;
  font-weight: 700;
}
</style>

<style lang="scss" scoped>
.section-container {
  padding: 0 rem(24);
}

.grid-container span {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: rem(24) rem(24);

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: rem(24) rem(24);
  }

  @media screen and (min-width: 1224px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.category-card-content {
  width: 100%;
}

img {
  border-radius: rem(8);
}

.headline-cards-categories {
  font-size: rem(24);
  font-weight: 400;
  line-height: rem(27);
  margin: rem(56) 0 rem(24);

  @media screen and (min-width: 768px) {
    font-size: rem(36);
    line-height: rem(41);
    margin: rem(32) 0;
  }
}

.head-wrap {
  position: relative;
}

.arrow-icon {
  display: none;
  visibility: hidden;

  @media screen and (min-width: 1500px) {
    display: block;
    position: absolute;
    visibility: visible;
    z-index: 1;
    width: auto;
    height: rem(65);
    top: rem(30);
    left: rem(-70);
  }
}

.deal-cards-fade-enter-active,
.deal-cards-fade-leave-active {
  transition: opacity 0.9s, transform 0.7s;
  transform: translateY(0);
}

.deal-cards-fade-enter,
.deal-cards-fade-leave {
  opacity: 0;
  transform: translateY(-20px);
}

.fetch-more-disabled {
  filter: grayscale(100%);
  opacity: 0.4;
}

.button-wrap {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: rem(26);
}
</style>
