import { render, staticRenderFns } from "./TagSectionBanner.vue?vue&type=template&id=5b9161ff&scoped=true"
import script from "./TagSectionBanner.vue?vue&type=script&lang=js"
export * from "./TagSectionBanner.vue?vue&type=script&lang=js"
import style0 from "./TagSectionBanner.vue?vue&type=style&index=0&id=5b9161ff&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9161ff",
  null
  
)

export default component.exports