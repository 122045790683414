<template>
  <div class="ad-container">
    <div class="ad-container-inner">
      <div
        :id="`adup_BigAd_${elementId}`"
        class="advertisement"
        style="top: 0; left: 0; width: 100%; height: 432px;"
      ></div>
    </div>
  </div>
</template>

<script>
const placementKeys = {
  de: [
    '619b0caefea99d1e34c2c0bd0d9aae5d', // MOB
    '6acebecfc47df80a0a5703800957688d', // 2-Col
    '58543899c785c8232535a13c2255e149', // 3-Col
    '30e9c23d179834a587cb93e60200363c', // 4-Col
    '887fec7c861845145118733aefdaac02', // 5-Col
  ],
  at: [
    '3a070fced8e1d86e8a1c7e5352b25733', // MOB
    '0151dc51e683ff5ea8c2027cc91e7994', // 2-Col
    'aad2566ea92f9b4732440a0f18ad73c6', // 3-Col
    'd51d9787140adffa5ca2b62f05ad9e6b', // 4-Col
    '7ff81383910479be80610f175dd65e13', // 5-Col
  ],
  ch: [
    'c16a7ba9fa2efe196fde0640cad8ff10', // MOB
    '595be44e21016e3d61c84d6fa9b0dbd7', // 2-Col
    '0198f8a3feab8c2fc68167d57601b9a7', // 3-Col
    '34329930404a2e60e02553768b7eed21', // 4-Col
    'c8585fd1a08fb89970ed08d0fd179b47', // 5-Col
  ],
};

export default {
  props: {
    elementId: {
      type: Number,
      default: 0,
    },
    query: {
      type: String,
      default: '',
    },
    shouldInit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    adScriptsAvailable: false,
  }),
  mounted() {
    if (!window) return;

    if (typeof window.uAd === 'object') this.initAds();
    else
      (function(d, t) {
        const g = d.createElement(t);
        const s = d.getElementsByTagName(t)[0];
        g.src = 'https://s.d.adup-tech.com/jsapi';
        g.async = true;
        s.parentNode.insertBefore(g, s);
      })(document, 'script');
  },
  methods: {
    initAds() {
      const { locale } = this.$i18n;
      let adupPlacementKey = placementKeys[locale][0];

      if (window.innerWidth > 500) {
        adupPlacementKey = placementKeys[locale][1]; // 2Ads
      }
      if (window.innerWidth > 768) {
        adupPlacementKey = placementKeys[locale][2]; // 3Ads
      }
      if (window.innerWidth > 920) {
        adupPlacementKey = placementKeys[locale][3]; // 4Ads
      }
      if (window.innerWidth > 1200) {
        adupPlacementKey = placementKeys[locale][4]; // 5Ads
      }

      window.uAd.embed(`adup_BigAd_${this.elementId}`, {
        placementKey: adupPlacementKey,
        query: this.query,
        responsive: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ad-container {
  margin: 2rem auto;
  max-width: 1440px;
  padding: 0 2rem;
}

.ad-container-inner {
  margin: 0 auto;
}

@media screen and (min-width: 550px) {
  .deal-grid .ad-container {
    grid-column: 1 / span 2;
  }
}

@media screen and (min-width: 768px) {
  .deal-grid .ad-container {
    grid-column: 1 / span 3;
  }
}

@media screen and (min-width: 1024px) {
  .deal-grid .ad-container {
    grid-column: 1 / span 4;
  }
}
</style>
