<template>
  <div class="section-container content-container">
    <h2 class="affiliate-header" v-html="$t('affiliates.header')"></h2>
    <p class="affiliate-subhead">
      {{ $t('affiliates.subhead') }}
    </p>

    <div class="affiliate-cards-container">
      <a
        v-for="affiliate of affiliates"
        class="affiliate-card"
        :href="affiliate.link"
        target="blank"
        @click="() => pushGtmData(affiliate)"
      >
        <div>
          <img
            class="affiliate-card-image"
            :src="`/assets/affiliates/${affiliate.name}.png`"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    affiliates: [
      {
        name: 'corendon',
        link: 'https://ds1.nl/c/?si=729&li=44046&wi=336859&ws=frontpage_lastminute&dl=vakanties%2Flastminutes'
      },
      {
        name: 'dreizen',
        link: 'https://clk.tradedoubler.com/click?p=326304&a=3108861&epi=frontpage-lastminute&url=https%3A%2F%2Fwww.d-reizen.nl%2Flast-minute'
      },
      {
        name: 'de-vakantie-discounter',
        link: 'https://ds1.nl/c/?si=7805&li=1362777&wi=336859&ws=tm_inpaklijst&dl=last-minute'
      },
      {
        name: 'holidayguru-trips',
        link: 'https://trips.holidayguru.nl/'
      },
      {
        name: 'prijsvrij',
        link: 'https://ds1.nl/c/?si=2916&li=168050&wi=336859&ws=tm_inpaklijst&dl=last-minute'
      },
      {
        name: 'suntip',
        link: 'https://ds1.nl/c/?si=951&li=57956&wi=336859&ws=frontpage_lastminute&dl=last-minute'
      },
      {
        name: 'sunweb',
        link: 'https://www.sunweb.nl/vakantie/reizen?tt=1271_12_357260_tm-inpaklijst&r=%2Fvakantie%2Flast-minutes'
      },
      {
        name: 'tui',
        link: 'https://reis.tui.nl/c?c=433&m=12&a=357260&r=tm-inpaklijst&u=%2Flast-minute%2F'
      },
      {
        name: 'voordeeluitjes',
        link: 'https://voordeeluitjes.nl/eropuit/?tt=34295_12_357260_frontpage-lastminute&r=%2Factie%2Fzomervakantie-hotel-aanbiedingen%3Foccupancy%3D2A'
      },
      {
        name: 'zoover',
        link: 'https://tc.tradetracker.net/?c=35596&m=12&a=357260&r=tm-inpaklijst&u=%2Flastminute'
      }
    ]
  }),
  methods: {
    pushGtmData(affiliate) {
      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'homepage_click_to_affiliate_element',
        affiliateName: affiliate.name
      });
    }
  }
};
</script>

<style scoped lang="scss">
.section-container {
  padding: 0 rem(24);
}

.affiliate-header {
  color: #323231;
  font-size: rem(36);
  font-weight: 400;
}

.affiliate-subhead {
  font-size: rem(18);
}

.affiliate-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(24);
  margin-top: 32px;
}

.affiliate-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(8);
  height: rem(98);
  border-radius: rem(16);
  box-shadow: 4px 4px 8px 0px #3232311a;
  transition: 200ms;
}

.affiliate-card-image {
  width: 110%;
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(0.85);
}

.affiliate-card:hover .affiliate-card-image,
.affiliate-card:focus .affiliate-card-image {
  transform: scale(1);
}

@media screen and (min-width: 606px) {
  .affiliate-cards-container {
    display: flex;
    flex-wrap: wrap;
  }

  .affiliate-card {
    width: rem(251);
  }
}

.affiliate-card:hover,
.affiliate-card:focus {
  box-shadow: 4px 4px 8px 0px #00d5e01a;
}

.affiliate-card:active {
  box-shadow: 4px 4px 8px 0px #00d5e033;
}
</style>

<style lang="scss">
.affiliate-header > span {
  color: #00d5e0;
  font-weight: bold;
}
</style>
