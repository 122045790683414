/**
 * DealPage Mixin
 */
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    LazyImage: () => import('@/components/LazyImage'),
    LinkList: () => import('@/components/partials/LinkList'),
    Carousel: () => import('@/components/ui/SnapCarousel'),
    Slide: () => import('@/components/ui/SnapSlide'),
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
    }),
    ...mapState({
      selectedDeal: state => state.selectedDeal,
    }),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  methods: {
    isSectionVisible(section) {
      if (!section.visibilityStartDate && !section.visibilityEndDate)
        return true;
      if (
        !section.visibilityStartDate &&
        this.$dateFns.isAfter(new Date(section.visibilityEndDate), new Date())
      )
        return true;
      if (
        this.$dateFns.isAfter(
          new Date(),
          new Date(section.visibilityStartDate),
        ) &&
        !section.visibilityEndDate
      )
        return true;
      if (
        this.$dateFns.isAfter(
          new Date(),
          new Date(section.visibilityStartDate),
        ) &&
        this.$dateFns.isAfter(new Date(section.visibilityEndDate), new Date())
      )
        return true;
      return false;
    },
  },
};
