<template>
  <adSkeleton
    id-name="adup_contentAd_top"
    :placement-keys="placementKeys"
    :query="query"
    :locale="locale"
  />
</template>

<script>
import adSkeleton from '../../adSkeleton.vue'

export default {
  components: {
    adSkeleton
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    placementKeys: {
      default: () => {
        return {
          es: [
            'a8ebf87ad02264625ab6b8b690c1e834', // Desktop
            '6082c4bf2ed547cdfd15ea76908001ab', // Mobile
          ],
        }
      }
    }
  }
};
</script>

<style lang="scss">
.adup_contentAd_top {
  width: 100%;
  height: rem(360);

  @media screen and (min-width: 500px) {
    height: rem(410);
  }
}
</style>
