<template>
  <section v-if="allTravelMoods && allTravelMoods.length">
    <div class="section-container content-container">
      <div class="head-wrap">
        <h2
          v-html="$t('landingpage.titleMoods')"
          class="headline-cards-categories"
        />
        <p>{{ $t('landingpage.sublineMoods') }}</p>
        <Arrow class="arrow-icon" />
      </div>

      <div class="grid-container">
        <div
          v-for="(card, index) in shownCards"
          v-if="card.image"
          :key="`${index}_${card.startDate}`"
          name="deal-cards-fade"
        >
          <hg-link-handler
            :link="card.link"
            @click.native="() => pushDataLayer(card)"
          >
            <HgCardMoods
              :mood="card.name"
              :deals="$t('landingpage.ctaMoods')"
              :image="card.image"
              :alt="card.alt || 'alt'"
              class="card-content"
            />
          </hg-link-handler>
        </div>
      </div>

      <div class="button-wrap">
        <hg-button
          color="teal"
          @onClick="showMore"
          :class="{ 'fetch-more-disabled': !showMoreEnabled }"
          :disabled="!showMoreEnabled"
        >
          <template v-if="showMoreEnabled">
            {{ $t('showMoreCategories') }}
          </template>
          <template v-else>
            {{ $t('categoryCard.noMoreCategories') }}
          </template>
        </hg-button>
      </div>
    </div>
  </section>
</template>

<script>
import allTravelMoodsQuery from '@/apollo/queries/moods/allTravelMoods.gql';
import Arrow from '~/components/ui/icons/arrow';
import cardCategories from '~/components/sections/cardCategories';

export default {
  components: { Arrow },
  mixins: [cardCategories],

  watch: {
    allTravelMoods() {
      this.allHighlightCategories = this.allTravelMoods;
    }
  },

  data: () => ({
    allTravelMoods: [],
    showCategories: 5,
    showMoreEnabled: true,
    shownCards: []
  }),

  methods: {
    pushDataLayer(card) {
      this.$gtm.push({
        eventName: 'homepage_click_mood',
        mood: card.name,
        position: card.priority
      });
    },
    showCard() {
      if (this.showCategories >= this.categoryCards.length) {
        this.showMoreEnabled = false;
      }
      this.shownCards = this.categoryCards.slice(0, this.showCategories);
    },
    showMore() {
      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'homepage_click_show_more_moods'
      });
      this.showCategories += 5;
      this.showCard();
    }
  },

  apollo: {
    allTravelMoods: {
      query: allTravelMoodsQuery
    }
  }
};
</script>

<style lang="scss" scoped>
.head-wrap {
  position: relative;
  margin-bottom: rem(32);
}

.headline-cards-categories > span {
  color: $teal-500;
  font-weight: 700;
}

.section-container {
  padding: 0 rem(24);
}

.headline-cards-categories {
  font-size: rem(24);
  font-weight: 400;
  line-height: rem(27);
  margin: rem(56) 0 rem(24);

  @media screen and (min-width: 768px) {
    font-size: rem(36);
    line-height: rem(41);
    margin: rem(32) 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: rem(24);

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1224px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.arrow-icon {
  display: none;
  visibility: hidden;
  transform: scaleX(-1);

  @media screen and (min-width: 1500px) {
    display: block;
    position: absolute;
    visibility: visible;
    z-index: 1;
    width: auto;
    height: rem(80);
    top: rem(30);
    right: rem(-70);
  }
}

.deal-cards-fade-enter-active,
.deal-cards-fade-leave-active {
  transition: opacity 0.9s, transform 0.7s;
  transform: translateY(0);
}

.deal-cards-fade-enter,
.deal-cards-fade-leave {
  opacity: 0;
  transform: translateY(-20px);
}

.button-wrap {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: rem(26);
}

.fetch-more-disabled {
  filter: grayscale(100%);
  opacity: 0.4;
}
</style>
